<template>
  <div class="container">
    <header-back name='申请商户'></header-back>
    <section class="top44">
      <div
        class="cen"
        v-if="!isNext"
      >
        <van-field
          v-model="loginForm.mobile"
          type="tel"
          placeholder="请输入手机号码"
        />
        <van-field
          v-model="loginForm.code"
          center
          clearable
          maxlength="6"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <span
              :style="{'color': variables.textColor}"
              @click="getVerify()"
              v-html="count"
            >发送验证码</span>
          </template>
        </van-field>
        <van-field
          v-model="loginForm.mm"
          type="text"
          placeholder="请输入推广邀请码（必填）"
        />
        <div style="margin: 16px">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :color="variables.textColor"
            @click="onNext()"
          >下一步</van-button>

        </div>
      </div>
      <div
        class="cen"
        v-else
      >
        <van-field
          v-model="loginForm.name"
          type="text"
          placeholder="请输入门店名称"
        />
        <!-- <van-field
          v-model="loginForm.address"
          center
          clearable
          placeholder="请输入具体地址"
        >
          <template #button>
            <van-button
              round
              size="small"
              :color='variables.textColor'
              type="info"
              @click="onGetAddress"
            >
              <van-icon name="location-o" />获取定位
            </van-button>
          </template>
        </van-field> -->
        <div class="cen-img">
          <p class='cen-tit1'>请添加门店照片（三张）</p>
          <van-uploader
            v-model="fileList"
            multiple
          />
        </div>
        <p class='cen-tit2'>商家请如实填写、上传照片</p>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :color="variables.textColor"
            @click="onSave()"
          >提交申请</van-button>

        </div>
      </div>
    </section>

    <div
      v-show="show"
      id="allmap"
      class="map"
    ></div>

  </div>

</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import variables from "@/styles/variables.scss";
export default {
  components: { HeaderBack },
  data() {
    return {
      show: false,
      loginForm: {
        mobile: "",
        code: "",
        mm: "",
        name: "",
        address: "",
      },
      fileList: [],
      isNext: false,
      timer: null,
      count: "发送验证码",
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  created() {},
  mounted() {
    // 百度地图API功能
  },
  methods: {
    getLocation() {
      //获取位置
      if (navigator.geolocation) {
        let geolocation = new BMap.Geolocation(); // 将navigator.geolocation 替换成 new BMap.Geolocation() 完美解决window.navigator.geolocation.getCurrentPosition，在IOS10系统中无法定位问题
        geolocation.getCurrentPosition(
          (position) => {
            console.log("position", position);
            let x = position.latitude; // ---> 与H5 中的地理定位 若navigator.geolocation.getCurrentPosition(success,error,options)  则 let x = position.coords.latitude 一样   纬度
            let y = position.longitude; // 经度
            this.print(y, x);
          },
          (error) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                Toast("用户拒绝");
                // this.$refs.errortext.innerText =
                //   error.code + ":User denied the request for Geolocation."; // code == 1 用户拒绝
                break;
              case error.POSITION_UNAVAILABLE:
                Toast("无法获取");
                // this.$refs.errortext.innerText =
                //   "Location information is unavailable."; // code == 2 无法获取
                break;
              case error.TIMEOUT:
                Toast("请求超时");
                // this.$refs.errortext.innerText =
                //   "The request to get user location timed out."; // code == 3 请求超时
                break;
              case error.UNKNOWN_ERROR:
                Toast("一个未知的错误");
                // this.$refs.texterror.innerText = "An unknown error occurred."; //一个未知的错误
                break;
            }
          },
          this.options
        );
      }
    },
    print(y, x) {
      let that = this;
      this.show = true;
      let point = new BMap.Point(y, x); // -----> 当前y,x 坐标 是实时动态的经纬度值

      console.log("point", point);
      //弹出地图
      // let map = new BMap.Map("allmap"); // --->创建一个map实例  id='allmap' allmap是显示地图的元素的id
      // console.log("map", map);
      // map.centerAndZoom(point, 18); // --> 显示级数越大越细
      // map.addOverlay(new BMap.Marker(point)); // ---->定点坐标红点覆盖
      // map.enableScrollWheelZoom(true);
      let geoc = new BMap.Geocoder(); // --> 创建BMap.Geocoder 实例
      // map.addEventListener("click", function (e) {
      //   /** 通过点击百度地图，可以获取到对应的point, 由point的lng、lat属性就可以获取对应的经度纬度 */
      // let pt = e.point;
      //   console.log("pt", pt);
      let pt = point;
      geoc.getLocation(pt, function (rs) {
        /** addressComponents对象可以获取到详细的地址信息 */
        let addComp = rs.addressComponents;
        console.log("addComp", addComp);
        let site =
          addComp.province +
          "" +
          addComp.city +
          "" +
          addComp.district +
          "" +
          addComp.street +
          "" +
          addComp.streetNumber;
        console.log("site", site);
        that.show = false;
        that.loginForm.address = site;
      });
      // });
    },
    onNext() {
      if (!this.loginForm.mobile) {
        Toast("请输入手机号码");
        return;
      }
      if (!this.loginForm.code) {
        Toast("请输入验证码");
        return;
      }
      if (!this.loginForm.mm) {
        Toast("请输入推广邀请码");
        return;
      }
      this.isNext = true;
    },
    onSave() {
      //提交申请
    },
    // onGetAddress() {
    //   //获取当前地址
    //   this.getLocation();
    //   let watch1 = window.navigator.geolocation.watchPosition(this.getLocation); // watchPosition()方法：不停的获取和更新用户的地理位置信息，执行间隔时间0ms；当设备地理位置发生改变时，自动调用；  好像删除也么有影响
    //   console.log(watch1);
    //   //清除watch1
    //   window.navigator.geolocation.clearWatch(watch1);
    // },
    getVerify() {
      // 验证手机号
      if (!this.loginForm.mobile) {
        Toast("请输入手机号码");
        return;
      }
      const TIME_COUNT = 60; // 更改倒计时时间
      if (!this.timer) {
        // 发送验证码
        API.postSendSms(this.loginForm).then((response) => {
          Toast("发送成功");
        });
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            clearInterval(this.timer); // 清除定时器
            this.count = "重新获取";
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  height: 100%;
  background-color: #fff;
  padding: 0 15px;
  position: relative;
}
#allmap {
  width: 20rem;
  height: 20rem;
  margin: 50px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.cen {
  .van-cell {
    line-height: 36px;
  }
  .login {
    color: $textColor;
  }
  .cen-img {
    margin-top: 6px;
    border-radius: 22px;
    border: 1px solid #e8e8e8;
    padding: 12px;
  }
  .cen-tit1 {
    font-size: 14px;
    color: #c8c9cc;
    margin-bottom: 10px;
  }
  .cen-tit2 {
    font-size: 14px;
    color: #7f7f7f;
    margin: 10px 0;
  }
}
</style>
